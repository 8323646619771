import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import { Text, Image, Icon } from "@atoms";
import { AppLink } from "@base";

const CollectionCard = ({ collection, currentResource }) => {
  const list = useRef();

  useEffect(() => {
    const current = document.getElementById(currentResource);
    if (list.current) {
      const topPos = current.offsetTop;
      const height = current.clientHeight;
      list.current.scrollTop = topPos - height;
    }
  }, [list.current, currentResource]);

  return (
    <div className="relative rounded-xl bg-purple p-6 text-white">
      <Text variant="h6" className="mb-3 max-w-xs">
        {collection.hero.heading}
      </Text>
      <ul ref={list} className="md:max-h-[50vh] md:overflow-auto">
        {collection.resources.map((r, idx) => (
          <li id={r.slug}>
            <AppLink
              to={r.url}
              className={clsx(
                "block flex border-b-2 border-teal p-3 text-left transition duration-200",
                {
                  "pointer-events-none bg-white text-slate":
                    currentResource === r.slug,
                  "hover:bg-slate/50": currentResource !== r.slug,
                }
              )}
            >
              <div className="h-16 w-16 flex-shrink-0 pr-3">
                {r.image?.url && (
                  <div className="relative aspect-video">
                    <Image
                      image={r.image}
                      className="rounded-t-2xl"
                      fill
                      preserveAspectRatio
                    />
                  </div>
                )}
                {!r.image?.url && r.type && (
                  <div className="relative rounded-full bg-gray-light pb-[100%]">
                    <div className="absolute inset-0 flex items-center justify-center">
                      <Icon name={r.type} className="h-auto w-1/4 text-gray" />
                    </div>
                  </div>
                )}
              </div>
              <div>
                <Text
                  variant="label"
                  className={clsx({
                    "text-purple": currentResource === r.slug,
                    "text-teal": currentResource !== r.slug,
                  })}
                >
                  {`${String(idx + 1).padStart(2, "0")}.`}
                </Text>
                <Text variant="label" className="mt-1">
                  {r.title}
                </Text>
              </div>
            </AppLink>
          </li>
        ))}
      </ul>
      <div className="absolute bottom-6 left-0 right-0 h-6 bg-gradient-to-t from-purple" />
    </div>
  );
};

export default CollectionCard;
